// Wrap the entire code in an IIFE to avoid polluting the global scope


(function () {
    return;
    // Ensure the DOM is fully loaded before executing the script
    document.addEventListener('DOMContentLoaded', () => {
        // Expose the classes to the global scope
        window.Onboarding1 = Onboarding1;
        window.Onboarding2 = Onboarding2;

        // Instantiate the Onboarding1 class and expose it globally
        window.Onboarding1Instance = new Onboarding1();

        // Set up event listeners for your buttons outside the classes
        if (window.Onboarding1Instance) {
            // Event listener for onboarding-btn-1
            document.querySelector('.onboarding-btn-1').addEventListener('click', function () {
                window.Onboarding1Instance.startOnboarding();
            });

            // Event listener for onboarding-btn-switchCharacter
            document.querySelector('.onboarding-btn-switchCharacter').addEventListener('click', function () {
                window.Onboarding1Instance.handleSwitchCharacter();
            });

            // Event listener for onboarding-btn-selectCharacter
            document.querySelector('.onboarding-btn-selectCharacter').addEventListener('click', function () {
                // Call the method directly with the character name
                window.Onboarding1Instance.handleChooseGuide('alex');
            });

            // Event listener for onboarding-btn-close
            document.querySelector('.onboarding-btn-close').addEventListener('click', function () {
                window.Onboarding1Instance.closeOnboarding();
            });

            // Event listener for onboarding-btn-clearStorage
            document.querySelector('.onboarding-btn-clearStorage').addEventListener('click', function () {
                localStorage.clear();
                console.log('LocalStorage cleared');
            });

            // Event listener for onboarding-btn-2
            document.querySelector('.onboarding-btn-2').addEventListener('click', function () {
                // Instantiate Onboarding2 with a character name (e.g., 'alex')
                new window.Onboarding2('alex');
            });
        } else {
            console.error('Onboarding1Instance is not available.');
        }
    });
})();

class Onboarding1 {
    constructor() {
        this.apiData = {};
        this.questions = [];
        this.currentQuestionIndex = 0;
        this.results = { alex: 0, finn: 0, ana: 0, james: 0 };
        this.charactersData = [
            {
                charImg:
                    'https://subtle-chaja-e30081.netlify.app/images/homepage/guide/character%20thumbnail%20finn.png',
                match: 100,
                name: 'finn',
                role: 'The Jester',
            },
            {
                charImg:
                    'https://subtle-chaja-e30081.netlify.app/images/homepage/guide/character%20thumbnail%20ana.png',
                match: 90,
                name: 'ana',
                role: 'The Healer',
            },
            {
                charImg:
                    'https://subtle-chaja-e30081.netlify.app/images/homepage/guide/character%20thumbnail%20alex.png',
                match: 80,
                name: 'alex',
                role: 'The Strategist',
            },
            {
                charImg:
                    'https://subtle-chaja-e30081.netlify.app/images/homepage/guide/character%20thumbnail%20james.png',
                match: 80,
                name: 'james',
                role: 'The Warrior',
            },
        ];

        this.debugMode = true; // Set to false to disable logs

        this.initialize();
    }

    // Method to control logging
    log(message, ...optionalParams) {
        if (this.debugMode) {
            console.log(message, ...optionalParams);
        }
    }

    // Method to toggle debug mode
    setDebugMode(value) {
        this.debugMode = value;
    }

    // Initialize the onboarding process
    async initialize() {
        this.apiData = await this.fetchApiData();
        this.prepareQuestions();
        this.createOnboardingModal(); // Create the modal HTML
        this.setupEventListeners();
        this.checkCharacterSelection();
    }

    // Create the onboarding modal HTML
    createOnboardingModal() {
        const modalHtml = `
        <div class="onboarding-1 onboarding" style="display:none;">
            <div class="modal_ctn">
                <!-- Step 1 -->
                <div id="step1" class="modal_content" next="2" style="display:none;">
                    <div class="step1_contnet">
                        <div class="h1">${this.apiData.intro?.title || 'Welcome to Casino Crew'}</div>
                        <p>${this.apiData.intro?.description || 'You can either have one of our crew guide you for the best possible experience or you\'re a sea veteran and need no help.'}</p>
                    </div>
                    <!-- Added an ID to the button for easier selection -->
                    <button id="start-onboarding-btn" class="btn btn--1 next-modal">${this.apiData.intro?.start || 'LET\'s see the guide'}</button>
                    <button class="btn btn-skip">${this.apiData.intro?.cancel || 'I\'ll have the regular experience >'}</button>
                </div>

                <!-- Step 2 (Questions) -->
                <div id="step2" class="modal_content" next="3" style="display:none;">
                    <span class="go-back">
                        <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.33333 1.5L1 9.5M1 9.5L9.33333 17.5M1 9.5H21" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </span>
                    <div class="h1"></div>
                    <div class="q-label"></div>
                    <p></p>
                    <form></form>
                    <div class="q-legend"></div>
                </div>

                <!-- Step 3 (Final Step) -->
                <div id="step3" style="display:none;">
                    <section class="hp-characters-guide">
                        <div class="container">
                            <div class="heading">
                                <h2 class="title">${this.apiData.finalStepTitle || 'Your Map to Casino Adventure – Bonuses and Beyond!'}</h2>
                                <p>${this.apiData.finalStepDescription || 'Quisque consectetur fermentum ante sed volutpat. In id augue maximus, posuere est nec, lobortis enim. Suspendisse maximus, massa nec faucibus tristique, ex ligula volutpat turpis.'}</p>
                            </div>

                            <!-- Characters Carousel Guide -->
                            <div id="hp-characters-guide-carousel-onboarding-slider" class="splide" aria-label="Characters Carousel Guide">
                                <div class="splide__track">
                                    <div class="splide__list">
                                        <!-- Character slides will be generated here -->
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                </div>
            </div>
        </div>
        `;
        // Append the modal to the body
        document.body.insertAdjacentHTML('beforeend', modalHtml);
    }

    // Fetch data from the custom WordPress API
    async fetchApiData() {
        try {
            // Retrieve the current language using wpmlSettings
            const currentLanguage =
                typeof wpmlSettings !== 'undefined' && wpmlSettings.currentLanguage
                    ? wpmlSettings.currentLanguage
                    : 'default';

            // Construct the API URL with the current language
            const apiUrl = `/wp-json/custom-api/v1/onboarding?lang=${currentLanguage}`;

            const response = await fetch(apiUrl);
            if (!response.ok) {
                throw new Error('Network response was not OK');
            }
            const data = await response.json();
            this.log('API Data:', data);
            return data;
        } catch (error) {
            console.error('Fetch operation encountered a problem:', error);
            return null;
        }
    }

    // Prepare questions from API data or use defaults
    prepareQuestions() {
        if (
            this.apiData &&
            Array.isArray(this.apiData.cards) &&
            this.apiData.cards.length > 0
        ) {
            this.log('Mapping API data to question structure');
            this.questions = this.apiData.cards
                .map((card, index) => this.createQuestion(card, index))
                .filter((q) => q !== null);
        }

        if (this.questions.length === 0) {
            console.warn('No valid questions available.');
            return;
        }

        this.log('Questions for onboarding:', this.questions);
    }

    // Create a question object from API card data
    createQuestion(card, index) {
        // Dynamically extract all answers
        const answers = [];
        const answerKeys = Object.keys(card).filter((key) => key.startsWith('answers_'));
        if (answerKeys.length === 0) {
            console.warn('No answers found for card:', card);
            return null;
        }

        answerKeys.forEach((key) => {
            const answerData = card[key];
            if (answerData && answerData.answer) {
                answers.push({
                    text: answerData.answer || '',
                    value: {
                        alex: parseInt(answerData.alex) || 0,
                        finn: parseInt(answerData.finn) || 0,
                        ana: parseInt(answerData.ana) || 0,
                        james: parseInt(answerData.james) || 0,
                    },
                });
            }
        });

        if (answers.length === 0) {
            console.warn('No valid answers for card:', card);
            return null;
        }

        return {
            label: card.label || `Question ${index + 1}`,
            heading: card.heading || 'Choose what describes you best',
            text: card.question || '',
            answers: answers,
        };
    }

    // Set up event listeners for various interactions
    setupEventListeners() {
        // Internal event listeners for the class
        // 'Go Back' button to return to previous question
        document.addEventListener('click', (event) => {
            if (event.target.closest('.go-back')) {
                this.handleGoBack();
            }
        });

        // Event handler for question answers
        document.addEventListener('click', (event) => {
            if (event.target.matches('#step2 form input[type=radio]')) {
                this.handleAnswerSelection(event);
            }
        });

        // Event handler for choosing a guide
        document.addEventListener('click', (event) => {
            if (event.target.matches('.btn.btn--1.ftbn')) {
                this.handleChooseGuideClick(event);
            }
        });

        // Event handler for proceeding from Step 1 to Step 2
        document.addEventListener('click', (event) => {
            if (event.target.matches('#start-onboarding-btn')) {
                this.handleNextModalClick();
            }
        });

        // Event handler for skipping onboarding
        document.addEventListener('click', (event) => {
            if (event.target.matches('.btn-skip')) {
                this.handleSkip();
            }
        });
    }

    // Start Onboarding
    startOnboarding() {
        this.log('Starting Onboarding');
        document.querySelector('.onboarding-1').style.display = 'block';
        document.querySelector('#step1').style.display = 'block';
    }

    // Close Onboarding
    closeOnboarding() {
        this.log('Closing Onboarding');
        document.querySelector('.onboarding-1').style.display = 'none';
    }

    // Handle the click on the "LET's see the guide" button
    handleNextModalClick() {
        this.log('Proceeding to Step 2');
        document.querySelector('#step1').style.display = 'none';
        this.currentQuestionIndex = 0;
        this.results = { alex: 0, finn: 0, ana: 0, james: 0 };
        this.showQuestion(this.currentQuestionIndex);
    }

    // Display the current question
    showQuestion(index) {
        this.log('Showing Question', index + 1);
        const question = this.questions[index];

        // Update DOM elements with the question data
        document.querySelector('#step2 .h1').textContent = question.heading;
        document.querySelector('#step2 .q-label').textContent = question.label;
        document.querySelector('#step2 p').textContent = question.text;
        document.querySelector('#step2 .q-legend').textContent = `${index + 1}/${this.questions.length}`;

        const form = document.querySelector('#step2 form');
        form.innerHTML = '';

        question.answers.forEach((answer, i) => {
            const radioId = `question-${index}-answer-${i}`;
            const label = document.createElement('label');
            label.className = 'form-control';
            label.innerHTML = `
                <input type="radio" name="radio" id="${radioId}" data-value='${JSON.stringify(answer.value)}' />
                ${answer.text}
            `;
            form.appendChild(label);
        });

        document.querySelector('#step2').style.display = 'block';
    }

    // Handle user's answer selection
    handleAnswerSelection(event) {
        const selectedValue = JSON.parse(event.target.getAttribute('data-value'));

        // Update results based on the selected answer
        Object.keys(this.results).forEach((key) => {
            this.results[key] += selectedValue[key];
        });

        this.log('Updated Results:', this.results);

        // Uncheck the selected option
        const radios = document.querySelectorAll('#step2 form input[type=radio]');
        radios.forEach((radio) => {
            radio.checked = false;
        });

        if (this.currentQuestionIndex < this.questions.length - 1) {
            this.currentQuestionIndex++;
            this.showQuestion(this.currentQuestionIndex);
        } else {
            // Proceed to character selection and display final results
            document.querySelector('#step2').style.display = 'none';
            document.querySelector('#step3').style.display = 'block';

            // Create characters array with calculated match percentages
            const characters = this.calculateCharacterMatches();

            // Sort characters by match percentage
            characters.sort((a, b) => parseInt(b.match) - parseInt(a.match));

            // Render the character selection
            this.renderCharacterSelection(characters);
        }
    }

    // Calculate match percentages for characters
    calculateCharacterMatches() {
        return [
            {
                charImg:
                    'https://subtle-chaja-e30081.netlify.app/images/homepage/guide/character%20thumbnail%20alex.png',
                match: this.calculateMatch(this.results.alex),
                name: 'alex',
                role: 'The Strategist',
            },
            {
                charImg:
                    'https://subtle-chaja-e30081.netlify.app/images/homepage/guide/character%20thumbnail%20finn.png',
                match: this.calculateMatch(this.results.finn),
                name: 'finn',
                role: 'The Jester',
            },
            {
                charImg:
                    'https://subtle-chaja-e30081.netlify.app/images/homepage/guide/character%20thumbnail%20ana.png',
                match: this.calculateMatch(this.results.ana),
                name: 'ana',
                role: 'The Healer',
            },
            {
                charImg:
                    'https://subtle-chaja-e30081.netlify.app/images/homepage/guide/character%20thumbnail%20james.png',
                match: this.calculateMatch(this.results.james),
                name: 'james',
                role: 'The Warrior',
            },
        ];
    }

    // Calculate match percentage
    calculateMatch(score) {
        const maxScore = 100;
        let matchPercentage = (score / maxScore) * 100;
        if (matchPercentage > 100) matchPercentage = 100;
        return `${Math.round(matchPercentage)}%`;
    }

    // Render character selection
    renderCharacterSelection(characters) {
        const splideList = document.querySelector('.splide__list');
        splideList.innerHTML = ''; // Clear previous content

        characters.forEach((character) => {
            const characterCard = document.createElement('div');
            characterCard.className = 'splide__slide character-card-selector';
            characterCard.setAttribute('data-name', character.name.toLowerCase());
            characterCard.innerHTML = `
                <div class="card" style="background-image: url('${character.charImg}')">
                    ${
                        parseInt(character.match) > 0 && parseInt(character.match) <= 100
                            ? `
                    <div class="match-ctn">
                        <div class="match">${character.match}</div>
                        <div class="bonus">MATCH</div>
                    </div>
                    `
                            : ''
                    }
                    <div class="content">
                        <div class="d-flex align-items-center justify-content-between mb-3">
                            <div class="name">${character.name}</div>
                            <div class="role">${character.role}</div>
                        </div>
                        <a href="#" class="btn btn--1 ftbn" data-name="${character.name.toLowerCase()}" data-match="${character.match}">CHOOSE GUIDE</a>
                    </div>
                </div>
            `;
            splideList.appendChild(characterCard);
        });

        // Initialize Splide carousel
        
        
        new Splide('#hp-characters-guide-carousel-onboarding-slider', {
            arrows: false,
            pagination: false,
            gap: 10,
            autoplay: true,
            interval: 2500,
            type: 'loop',
            perPage: 4,
            autoplay: false,
            breakpoints: {
                1200: {
                    autoplay: true,
                    perPage: 2,
                },
                768: {
                    perPage: 1,
                },
            },
        }).mount();
    }

    // Handle choosing a guide
    handleChooseGuideClick(event) {
        event.preventDefault();
        const characterName = event.target.getAttribute('data-name');
        this.handleChooseGuide(characterName);
    }

    // Direct method to choose a guide
    handleChooseGuide(characterName) {
        this.log('Choosing Guide:', characterName);
        // Save selected character to localStorage
        localStorage.setItem('CrewSelectedCharacter', characterName);

        // Hide onboarding modal
        document.querySelector('.onboarding-1.onboarding').style.display = 'none';

        // Update character image in the navigation
        this.updateCharacterImage(characterName);

        // Initialize Onboarding2 with the selected character
        new Onboarding2(characterName);
    }

    // Update the character image in the navigation
    updateCharacterImage(characterName) {
        const bottomNavImg = document.querySelector('.bottom-nav__msg img');
        if (bottomNavImg) {
            const imagePath = `${window.location.origin}/wp-content/plugins/casinocrewblocks/assets/img/selectedCharacter/${characterName}.png`;
            bottomNavImg.src = imagePath;
            this.log(`Updated bottom-nav__msg image src: ${bottomNavImg.src}`);
        }
    }

    // Check character selection and manage onboarding display
    checkCharacterSelection() {
        const existingCharacter = localStorage.getItem('CrewSelectedCharacter');
        const skipOnboarding = localStorage.getItem('CrewSkipOnboarding');
        const skipTimestamp = localStorage.getItem('CrewSkipOnboardingTimestamp');
        const now = Date.now();
        const twoMinutes = 2 * 60 * 1000; // 2 minutes in milliseconds

        let showOnboarding = false;

        if (!existingCharacter) {
            if (!skipOnboarding || !skipTimestamp) {
                showOnboarding = true;
            } else {
                const elapsed = now - parseInt(skipTimestamp, 10);
                if (elapsed > twoMinutes) {
                    showOnboarding = true;
                } else {
                    this.log(
                        `Onboarding will be shown again in ${Math.ceil(
                            (twoMinutes - elapsed) / 1000
                        )} seconds.`
                    );
                }
            }

            if (showOnboarding) {
                document.querySelector('.onboarding-1').style.display = 'block';
                document.querySelector('#step1 .step1_contnet').innerHTML = this.apiData.intro?.intro || '';
                document.querySelector('#step1 .next-modal').textContent = this.apiData.intro?.start || 'LET\'s see the guide';
                document.querySelector('#step1 .btn-skip').textContent = this.apiData.intro?.cancel || 'I\'ll have the regular experience >';
                document.querySelector('#step1').style.display = 'block';
            } else {
                this.log('Onboarding will not be shown at this time.');
            }
        } else {
            // Update character image if character is already selected
            this.updateCharacterImage(existingCharacter);
        }
    }

    // Handle skip action
    handleSkip() {
        this.log('Onboarding skipped');
        document.querySelector('.onboarding-1').style.display = 'none';
        // Store skip status in localStorage
        localStorage.setItem('CrewSkipOnboarding', 'true');
        localStorage.setItem('CrewSkipOnboardingTimestamp', Date.now().toString());
    }

    // Handle 'Go Back' functionality
    handleGoBack() {
        if (this.currentQuestionIndex > 0) {
            this.currentQuestionIndex--;
            this.showQuestion(this.currentQuestionIndex);
        } else {
            document.querySelector('#step2').style.display = 'none';
            document.querySelector('#step1').style.display = 'block';
        }
    }

    // Handle switching character
    handleSwitchCharacter() {
        this.log('Switching character');
        const existingCharacter = localStorage.getItem('CrewSelectedCharacter');

        if (existingCharacter) {
            // Reorder charactersData to make the selected character first
            const selectedCharacter = this.charactersData.find(
                (character) => character.name === existingCharacter
            );
            if (selectedCharacter) {
                this.charactersData = [
                    selectedCharacter,
                    ...this.charactersData.filter(
                        (character) => character.name !== existingCharacter
                    ),
                ];
            }

            // Update localStorage with the new character selection
            const firstCharacter = this.charactersData[0].name;
            localStorage.setItem('CrewSelectedCharacter', firstCharacter);

            // Render the character selection
            this.renderCharacterSelection(this.charactersData);

            document.querySelector('.onboarding-1.onboarding').style.display = 'block';
            document.querySelector('#step3').style.display = 'block';
        } else {
            this.log('No character selected in localStorage.');
        }
    }
}

class Onboarding2 {
    constructor(characterName) {
        if (!characterName) {
            console.error('Character name is required to initialize Onboarding2.');
            return;
        }
        this.characterName = characterName;
        this.debugMode = true; // Set to false to disable logs

        this.initialize();
    }

    // Method to control logging
    log(message, ...optionalParams) {
        if (this.debugMode) {
            console.log(message, ...optionalParams);
        }
    }

    // Method to toggle debug mode
    setDebugMode(value) {
        this.debugMode = value;
    }

    initialize() {
        this.log('Onboarding2 initialized with character:', this.characterName);

        // Display the Onboarding2 modal
        this.showOnboarding2Modal();
    }

    showOnboarding2Modal() {
        // Use the provided HTML for the Onboarding2 modal
        const modalHtml = `
        <div class="onboarding-2 onboarding" style="display: block;">
            <div class="modal_ctn">
                <!-- Step 1 -->
                <div id="step1" class="modal_content" next="2">
                    <div class="h1">HOME Want a step-by-step tour of the ship?</div>
                    <div class="image-ctn">
                        <img src="https://subtle-chaja-e30081.netlify.app/images/finn-yacht.png" alt="finn yacht">
                    </div>
                    <button class="btn btn--1 next-modal">Yeah, let's see it</button>
                    <button class="btn btn-skip">I'll have the regular experience ></button>
                </div>
                <!-- Step 2 -->
                <div id="step2" class="modal_content" style="display: none;">
                    <div class="image-ctn">
                        <img src="https://subtle-chaja-e30081.netlify.app/images/finn-yacht.png" alt="finn yacht">
                    </div>
                    <div class="h1">FREE SPINS Cras pharetra nibh lectus, quis dignissim FREE SPINS</div>
                    <p>Quisque consectetur fermentum ante sed volutpat. In id augue maximus, posuere est nec, lobortis enim</p>
                    <span id="step2-next">
                        <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.52155 15.7203L16.071 15.956M16.071 15.956L15.8353 4.40657M16.071 15.956L1.92883 1.81385" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                    </span>
                </div>
            </div>
        </div>
        `;

        // Append the modal to the body
        document.body.insertAdjacentHTML('beforeend', modalHtml);

        // Set up event listeners for Onboarding2
        this.setupEventListeners();
    }

    setupEventListeners() {
        // Event handler to proceed from Step 1 to Step 2
        document.addEventListener('click', (event) => {
            if (event.target.matches('.onboarding-2 .next-modal')) {
                document.querySelector('.onboarding-2 #step1').style.display = 'none';
                document.querySelector('.onboarding-2 #step2').style.display = 'block';
                
                // Log after button click
                this.log('User clicked the "next-modal" button.');

                // Call PopupMessage class to insert HTML
                const popupOptions = {
                    message: 'Quisque consectetur fermentum ante sed volutpat. In id augue maximus!',
                    linkText: 'Lorem ipsum >',
                    onLinkClick: () => {
                    console.log('Callback executed.');
                    const popupSequence = new PopupSequence(steps);
                    popupSequence.start();
                    }
                    // Removed svgPath and svgFill as they are no longer needed
                };

                const popup = new PopupMessage(popupOptions);
                popup.show();
            }
        });

        // Event handler for the skip button
        document.addEventListener('click', (event) => {
            if (event.target.matches('.onboarding-2 .btn-skip')) {
                this.finishOnboarding();
            }
        });

        // Event handler for finishing Onboarding2 (assuming there's a finish button in Step 2)
        document.addEventListener('click', (event) => {
            if (event.target.matches('.onboarding-2 #step2 span')) {
                this.finishOnboarding();
            }
        });
    }

    finishOnboarding() {
        // Hide and remove the Onboarding2 modal
        const onboarding2Modal = document.querySelector('.onboarding-2');
        if (onboarding2Modal) {
            onboarding2Modal.remove();
            this.log('User has finished Onboarding2.');
        }

        // Perform any additional actions upon completion
        // For example, you might update the UI or navigate to a different page
    }
}


class PopupMessage {
    constructor(options = {}) {
        // Configurare implicită
        const defaults = {
            containerSelector: '.bottom-nav__msg',
            message: 'Quisque consectetur fermentum ante sed volutpat. In id augue maximus!',
            linkText: 'Lorem ipsum >',
            // Eliminăm linkHref deoarece nu mai este necesar
            // Eliminăm svgPath și svgFill
            // Adăugăm o proprietate pentru callback
            onLinkClick: null // Funcție care va fi apelată la clic pe link
        };
        
        // Combină opțiunile implicite cu cele furnizate
        this.options = { ...defaults, ...options };
        
        // Selectează containerul țintă
        this.container = document.querySelector(this.options.containerSelector);
        
        if (!this.container) {
            console.error(`Elementul cu selectorul "${this.options.containerSelector}" nu a fost găsit.`);
            return;
        }

        // Verifică dacă popup-ul este deja inserat
        if (this.container.querySelector('.popup__ctn')) {
            console.warn('PopupMessage este deja inserat în container.');
            return;
        }

        // Bind pentru metodele de gestionare a evenimentelor
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.handleEscPress = this.handleEscPress.bind(this);
        this.handleLinkClick = this.handleLinkClick.bind(this);
    }

    // Metodă pentru a crea HTML-ul popup-ului
    createPopupHtml() {
        const { message, linkText } = this.options;
        
        return `
            <div class="popup__ctn visible">
                <p>${message}</p>
                <a href="#" class="btn btn--3 popup-link">${linkText}</a>
                <svg
                    class="popup__close-icon"
                    width="11"
                    height="10"
                    viewBox="0 0 11 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="cursor: pointer;"
                    aria-label="Close popup"
                >
                    <path
                        d="M0.491523 10L4.04652 5.086L0.563523 0.28H3.11952L5.30652 3.385L7.45752 0.28H10.0135L6.53052 5.086L10.1035 10H7.54752L5.30652 6.787L3.04752 10H0.491523Z"
                        fill="#232323"
                    />
                </svg>
            </div>
        `;
    }

    // Metodă pentru a insera popup-ul în DOM și a iniția tranziția de fade-in
    show() {
        if (!this.container) return;
        
        const popupHtml = this.createPopupHtml();
        this.container.insertAdjacentHTML('beforeend', popupHtml);
        console.log('PopupMessage a fost inserat în container.');

        // Selectează elementul popup
        this.popup = this.container.querySelector('.popup__ctn');

        if (this.popup) {
            // Folosim jQuery pentru a aplica efectul de fade-in
            $(this.popup).hide().fadeIn(500);

            // Adăugarea event listener-ului pe SVG pentru a ascunde popup-ul
            const closeIcon = this.popup.querySelector('.popup__close-icon');
            if (closeIcon) {
                closeIcon.addEventListener('click', () => this.hide());
            }

            // Adăugarea event listener-ului pe link pentru a ascunde popup-ul și a executa callback-ul
            const link = this.popup.querySelector('.popup-link');
            if (link) {
                link.addEventListener('click', this.handleLinkClick);
            }

            // Adăugarea event listener-ului pentru a închide popup-ul când se face clic în afara acestuia
            document.addEventListener('click', this.handleOutsideClick);

            // Adăugarea event listener-ului pentru a închide popup-ul la apăsarea tastei Esc
            document.addEventListener('keydown', this.handleEscPress);
        }
    }

    // Metodă pentru a actualiza textul mesajului
    updateMessage(newMessage) {
        const popup = this.container.querySelector('.popup__ctn p');
        if (popup) {
            popup.textContent = newMessage;
            console.log('Mesajul popup-ului a fost actualizat.');
        } else {
            console.warn('Elementul <p> din popup nu a fost găsit.');
        }
    }

    // Metodă pentru a actualiza textul link-ului
    updateLink(newText) {
        const link = this.container.querySelector('.popup__ctn a');
        if (link) {
            link.textContent = newText;
            console.log('Link-ul popup-ului a fost actualizat.');
        } else {
            console.warn('Elementul <a> din popup nu a fost găsit.');
        }
    }

    // Metodă pentru a ascunde popup-ul cu efect de fade-out
    hide(callback = null) {
        if (!this.popup) {
            console.warn('PopupMessage nu este prezent.');
            return;
        }

        // Folosim jQuery pentru a aplica efectul de fade-out
        $(this.popup).fadeOut(500, () => {
            this.remove();
            console.log('PopupMessage a fost eliminat din container.');
            if (typeof callback === 'function') {
                callback();
            }
        });

        // Ascundem și suprapunerea (overlay) cu fade-out
        $('.overlay').fadeOut(500, function() {
            $(this).remove();
        });

        // Eliminarea event listener-elor
        document.removeEventListener('click', this.handleOutsideClick);
        document.removeEventListener('keydown', this.handleEscPress);
    }

    // Metodă pentru a elimina popup-ul imediat (fără fade-out)
    remove() {
        if (this.popup) {
            this.popup.remove();
            this.popup = null;
            console.log('PopupMessage a fost eliminat din container.');
        } else {
            console.warn('PopupMessage nu a fost găsit în container.');
        }
    }

    // Metodă pentru a gestiona clicurile în afara popup-ului
    handleOutsideClick(event) {
        if (this.popup && !this.popup.contains(event.target)) {
            this.hide();
        }
    }

    // Metodă pentru a gestiona apăsarea tastei Esc
    handleEscPress(event) {
        if (event.key === 'Escape') {
            this.hide();
        }
    }

    // Metodă pentru a gestiona clicul pe link
    handleLinkClick(event) {
        event.preventDefault(); // Previne comportamentul implicit al link-ului
        this.hide(() => {
            if (typeof this.options.onLinkClick === 'function') {
                this.options.onLinkClick();
            }
        });
    }
}

// Noua clasă PopupSequence
class PopupSequence {
    constructor(steps = []) {
        this.steps = steps;
        this.currentIndex = 0;
        this.popupMessage = null;
    }

    start() {
        this.showOverlay();
        this.dimElements();
        this.showCurrentStep();
    }

    showOverlay() {
        if (!$('.overlay').length) {
            $('body').append('<div class="overlay"></div>');
            $('.overlay').fadeIn(500);
        }
    }

    hideOverlay() {
        $('.overlay').fadeOut(500, function() {
            $(this).remove();
        });
    }

    dimElements() {
        // Diminuează toate elementele relevante
        $('.bottom-nav__ctn > a').css('filter', 'brightness(0.5)');
    }

    showCurrentStep() {
        if (this.currentIndex >= this.steps.length) {
            this.endSequence();
            return;
        }

        const step = this.steps[this.currentIndex];
        this.highlightElement(step.selector);

        const options = {
            message: step.message,
            linkText: step.linkText || 'Next',
            onLinkClick: () => {
                this.resetHighlight(step.selector);
                if (typeof step.onLinkClick === 'function') {
                    step.onLinkClick();
                }
                this.currentIndex++;
                this.showCurrentStep();
            }
        };

        this.popupMessage = new PopupMessage(options);
        this.popupMessage.show();
    }

    highlightElement(selector) {
        $(selector).css({
            'outline': '2px solid #fff',
            'outline-offset': '5px',
            'filter': 'brightness(1)'
        });
    }

    resetHighlight(selector) {
        $(selector).css({
            'outline': 'unset',
            'outline-offset': 'unset',
            'filter': 'brightness(0.5)'
        });
    }

    endSequence() {
        this.hideOverlay();
        $('.bottom-nav__ctn > a').css('filter', 'brightness(1)');
        this.steps.forEach(step => this.resetHighlight(step.selector));
        console.log('Secvența de popup-uri s-a încheiat.');
    }
}

// Definim pașii secvenței
const steps = [
    {
        selector: '.bottom-nav__ctn > a:eq(0)',
        message: 'This is Home',
        onLinkClick: () => {
            console.log('Free Spins.');
        }
    },
    {
        selector: '.bottom-nav__ctn > a:eq(1)',
        message: 'This is Free Spins',
        onLinkClick: () => {
            console.log('Al doilea pas completat.');
        }
    },
    {
        selector: '.bottom-nav__ctn > a:eq(2)',
        message: 'Top Casinos',
        onLinkClick: () => {
            console.log('Al treilea pas completat.');
        }
    },

    {
        selector: '.bottom-nav__ctn > a:eq(3)',
        message: 'Free Slots.',
        onLinkClick: () => {
            console.log('Last step.');
        }
    },
    {
        message: 'Go to next step',
        onLinkClick: () => {
            console.log('Next step.');
        }
    }
];